body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

main {
  padding: 30px;
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
}

article {
  max-width: 640px;
}


/* h1 { width: 200px;} */

i { font-family: monospace;}

header {
  background-color: #191970;
  color: white;
  padding: 0;
}


header > div {
  display: flex;
  padding: 0 30px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1rem; 
  font-weight: normal;
  
}

.menu {
  align-self: center;
}


.menu > a  {
  color: #fd7d00;
  text-decoration: none;
  padding: 37px 30px;
  transition: 0.3s;
}


.menu a:hover {
  color: #fd7d00;
  background-color: #fff;
}

header a img {
  width: 150px;
  height: 70px;
  margin: 10px 0px;  
}

header a:hover img {
  background-color: none;
}

.App-link {
  color: #191970;
}

button { 
  padding: 10px 30px; 
  margin: 30px 0; 
  background-color: #191970;
  border: 0px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  opacity: 1;
}

button:hover {
  /* background-color: #fd7d00; */
  opacity: 0.9;
  transition-duration: 0.2s;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button.mm-button {
  background-color: #fd7d00;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

section {
  margin: 20px 0 50px 0;
  padding: 0px;
  border-radius: 10px; 
  border: 1px solid #191970;
  justify-content: center;
}

section div {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

section div:first-child {
  justify-content: start;
  border-radius: 10px 10px 0 0; 
  padding: 20px 40px;
  background-color: #191970;
  color: #fff;
  font-weight: bold;
}

section div:last-child {
  border-radius: 0 0 10px 10px; 
  padding: 40px;
  font-size: 1rem;
  color: #000;
  display: flex;
  justify-content: space-between;
}

/* section:last-child div {
  justify-content: start;
} */

section div span.balance {
  font-size: 1rem;
  font-weight: bold;
  /* min-width: 100px; */
}


section div span.title {
  font-size: 1.3rem;
}

div.netInfo {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: flex-end;
}

.netInfo span {
  margin: 5px;
}

.bttn-n-status {
  display: flex;
  align-items: center;
}

.status, footer, .copyright {
  display: flex;
  justify-content: center;
}

.copyright {
  padding: 20px 0;
}

.error {
  color: #fd7d00
}

.contracts {
  font-family: monospace;
  font-size: 1.1rem;
}
.contracts a {color: #fd7d00; }